import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Nav from './Nav';
import { useLocation } from 'react-router-dom';

const services = [
  {
    id: 'massage1',
    title: 'Massage relaxant',
    description: 'Un massage doux pour vous détendre et évacuer le stress.',
    image: 'https://example.com/image1.jpg'
  },
  {
    id: 'massage2',
    title: 'Massage sportif',
    description: 'Un massage pour les athlètes visant à réduire les douleurs musculaires.',
    image: 'https://example.com/image2.jpg'
  },
  {
    id: 'massage3',
    title: 'Massage thérapeutique',
    description: 'Un massage ciblé pour traiter des problèmes spécifiques.',
    image: 'https://example.com/image3.jpg'
  }
  // Ajoutez d'autres services ici
];

function ChooseService() {
  const [selectedService, setSelectedService] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { address, numberOfPeople, duration, selectedTherapist, selectedDate } = location.state || {};

  const handleSubmit = (event) => {
    event.preventDefault();
    navigate('/payment', { state: { address, numberOfPeople, duration, selectedTherapist, selectedDate, selectedService } });
  };

  return (
    <>
      <Nav />
      <div className="container container-location">
        <h2>Choisissez le type de soin</h2>
        <form onSubmit={handleSubmit}>
          <div className="services-container">
            {services.map(service => (
              <div
                key={service.id}
                className={`service-card ${selectedService === service.id ? 'selected' : ''}`}
                style={{ backgroundImage: `url(${service.image})` }}
              >
                <div className="service-info">
                  <div className="service-text">
                    <h3>{service.title}</h3>
                    <a href="#" className="more-info">Plus d'infos &gt;</a>
                  </div>
                  <button
                    type="button"
                    className="select-button"
                    onClick={() => setSelectedService(service.id)}
                  >
                    Sélectionner
                  </button>
                </div>
              </div>
            ))}
          </div>
          <button type="submit" className="btn btn-primary mt-4">
            Suivant
          </button>
        </form>
      </div>
    </>
  );
}

export default ChooseService;
