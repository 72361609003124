import { Routes, Route } from "react-router-dom";
import Layout from "./Components/Layout";
import Login from "./Components/Login";
import Dashboard from "./Components/Dashboard";
import Accueil from "./Components/Accueil";
import User from "./Components/User/User";
import "./App.css";
import RequireAuth from "./Components/RequireAuth";
import PersistLogin from "./Components/PersistLogin";
import ChooseLocation from "./Components/ChooseLocation";
import ChooseDuration from "./Components/ChooseDuration";
import ChooseTherapist from "./Components/ChooseTherapist";
import ChooseService from "./Components/ChooseService";
import Payment from "./Components/Payment";
import Register from "./Components/Register";
import ValidationPage from "./Components/ValidationPage";

const ROLES = {
  Client: "ROLE_USER",
  Editor: "ROLE_USER",
  Admin: "ROLE_SUPER_ADMIN",
};

function App() {
  console.log("test");
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="register" element={<Register />} />
        <Route path="login" element={<Login />} />
        <Route path="accueil" element={<Accueil />} />
        <Route path="validation" element={<ValidationPage />} />
        <Route path="choose-location" element={<ChooseLocation />} />
        <Route path="choose-duration" element={<ChooseDuration />} />
        <Route path="choose-therapist" element={<ChooseTherapist />} />
        <Route path="choose-service" element={<ChooseService />} />
        <Route path="payment" element={<Payment />} />
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth allowedRoles={[ROLES.Client]} />}>
            <Route path="" element={<Dashboard />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[ROLES.Client]} />}>
            <Route path="users" element={<User />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
