import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Nav from './Nav';
import therapeut1 from '../assets/images/therapeut1.png';

const therapists = [
  {
    id: 1,
    name: 'Thérapeute 1',
    rating: 4.5,
    image: therapeut1
  },
  {
    id: 2,
    name: 'Thérapeute 2',
    rating: 4.7,
    image: therapeut1
  }
  // Ajoutez d'autres thérapeutes ici
];

function ChooseTherapist() {
  const [selectedTherapist, setSelectedTherapist] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState('');
  const location = useLocation();
  const { address, numberOfPeople, duration } = location.state || {};
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      setSelectedDate(now);
      setSelectedTime(now.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' }));
    }, 1000 * 60); // Update every minute
    return () => clearInterval(interval);
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    navigate('/choose-service', {
      state: {
        address,
        numberOfPeople,
        duration,
        selectedTherapist,
        selectedDate: selectedDate.toISOString(),
        selectedTime
      }
    });
  };

  const handleMonthChange = (direction) => {
    const newDate = new Date(selectedDate);
    newDate.setMonth(newDate.getMonth() + direction);
    if (newDate >= new Date()) {
      setSelectedDate(newDate);
    }
  };

  const handleDayChange = (direction) => {
    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate() + direction);
    if (newDate >= new Date()) {
      setSelectedDate(newDate);
    }
  };

  const handleHourChange = (direction) => {
    const newDate = new Date(selectedDate);
    newDate.setHours(newDate.getHours() + direction);
    setSelectedDate(newDate);
    setSelectedTime(newDate.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' }));
  };

  const getMonthName = (monthIndex) => {
    const date = new Date();
    date.setMonth(monthIndex);
    return date.toLocaleString('fr-FR', { month: 'long' });
  };

  const getDayName = (date) => {
    return date.toLocaleString('fr-FR', { weekday: 'short' });
  };

  const getNextHourSlots = (date) => {
    const now = new Date();
    const hours = [];

    if (date.toDateString() === now.toDateString()) {
      // Pour aujourd'hui, commencez à la prochaine heure pleine ou au prochain quart d'heure
      const minutes = now.getMinutes();
      const nextQuarterHour = Math.ceil(minutes / 15) * 15;
      now.setMinutes(nextQuarterHour);
      now.setSeconds(0);

      for (let i = 0; i < 16; i++) {
        const slot = new Date(now.getTime() + i * 15 * 60000);
        hours.push(slot.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' }));
      }
    } else {
      // Pour les jours suivants, commencez à 8h00
      const startOfDay = new Date(date);
      startOfDay.setHours(8, 0, 0, 0);

      for (let i = 0; i < 16; i++) {
        const slot = new Date(startOfDay.getTime() + i * 15 * 60000);
        hours.push(slot.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' }));
      }
    }

    return hours;
  };

  const hours = getNextHourSlots(selectedDate);

  const daysToShow = Array.from({ length: 9 }, (_, i) => {
    const date = new Date();
    date.setDate(new Date().getDate() + i); // Affiche le jour actuel et les 8 jours suivants
    return date;
  });

  return (
    <>
      <Nav />
      <div className="container container-therapeut">
        <h2>Choisissez votre masseur et la date</h2>
        <form className="form-masseur" onSubmit={handleSubmit}>
          <div className="form-group group-mois">
            <label>Mois</label>
            <div className="btn-group" role="group">
              <button type="button" className="btn-slide btn-prev" onClick={() => handleMonthChange(-1)}>
                &lt;
              </button>
              <span className="text-month">{getMonthName(selectedDate.getMonth())}</span>
              <button type="button" className="btn-slide btn-next" onClick={() => handleMonthChange(1)}>
                &gt;
              </button>
            </div>
          </div>
          <div className="form-group group-jour">
            <label>Jour</label>
            <div className="btn-group" role="group">
              <button type="button" className="btn-slide btn-prev" onClick={() => handleDayChange(-1)}>
                &lt;
              </button>
              {daysToShow.map((date) => (
                <button
                  key={date.getDate()}
                  type="button"
                  className={`btn-day ${date >= new Date() ? (date.toDateString() === selectedDate.toDateString() ? 'selected' : '') : 'disabled'}`}
                  onClick={() => setSelectedDate(date)}
                >
                  {`${getDayName(date)} ${date.getDate()}`}
                </button>
              ))}
              <button type="button" className="btn-slide btn-next" onClick={() => handleDayChange(1)}>
                &gt;
              </button>
            </div>
          </div>
          <div className="form-group group-heure">
            <label>Heure</label>
            <div className="btn-group" role="group">
              <button type="button" className="btn-slide btn-prev" onClick={() => handleHourChange(-1)}>
                &lt;
              </button>
              <div className="row-day">
                {hours.map((hour) => (
                  <button
                    type="button"
                    key={hour}
                    className={`btn-hour ${selectedTime === hour ? 'selected' : 'btn-outline-primary'}`}
                    onClick={() => setSelectedTime(hour)}
                  >
                    {hour}
                  </button>
                ))}
              </div>
              <button type="button" className="btn-slide btn-next" onClick={() => handleHourChange(1)}>
                &gt;
              </button>
            </div>
          </div>
          <div className="form-group group-masseur">
            {therapists.map((therapist) => (
              <div key={therapist.id} className="therapist-item">
                <img src={therapist.image} alt={therapist.name} className="therapist-image" />
                <div className="therapist-info">
                  <p className="name">{therapist.name}</p>
                  <p className="note">{therapist.rating}</p>
                  <button
                    type="button"
                    className={`btn-outline-primary ${selectedTherapist === therapist.id ? 'selected' : ''}`}
                    onClick={() => setSelectedTherapist(therapist.id)}
                  >
                    &gt;
                  </button>
                </div>
              </div>
            ))}
          </div>
          <button type="submit" className="btn btn-primary">
            Suivant
          </button>
        </form>
      </div>
    </>
  );
}

export default ChooseTherapist;
